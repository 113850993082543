function EmpDashCard({
  active,
  fieldCount,
  fieldImage,
  fieldName,
  handleClick,
}) {
  return (
    <div className=" xl:w-[25%] p-3 " >
      <div
        className={` ${active === fieldName ? 'bg-[#6B6DD8]' : 'bg-[#EBEDFD] '
          } px-3 gap-x-5 flex min-w-[200px] h-[120px] py-2 rounded-xl font-sans  cursor-pointer`}
        onClick={() => handleClick()}
      >
        <div className="flex items-center justify-center bg-white  rounded-full w-[56px] h-[56px] my-auto flex-shrink-0 ">
          <img
            className="object-fill p-2  "
            src={fieldImage}
            alt=""
          />
        </div>
        <div className="flex py-1 flex-col justify-center">
          <p className="mt-2  mb-0 capitalize text-[#A3AED0] text-sm poppins ">
            {fieldName}
          </p>
          <h2
            className={`${active === fieldName ? 'text-white' : 'text-black'} `}
          >
            {fieldCount}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default EmpDashCard;
