import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ViewTaskData from './ViewTaskData';
import SearchIcon from '../../SVG/SearchIcon';
import { useQueryState } from 'nuqs';
import { Store } from '../../Context/StateStore';
import TaskDashCard from './Cards/TaskDashCard';

const PendingList = ({ data, title, to }) => {
  let { employee, employeeUnderUser } = useContext(Store);

  const [selectedEmployee, setSelectedEmployee] =
    useQueryState('selectedEmployee');

  let navigate = useNavigate();
  let [taskid, setTaskId] = useState();
  let [subTask, setSubTask] = useState();
  return (
    <div>
      <section className="flex justify-between items-center p-2 ">
        <button
          onClick={() => navigate(to)}
          className="text-sm text-blue-600 fw-semibold "
        >
          View All
        </button>

        <h5 className="uppercase mt-2">
          {title} <span className="text-sm">({data?.length})</span>
        </h5>

        <article className="p-2 flex items-center border-2 rounded-lg">
          <select
            name=""
            id=""
          >
            <option value="">Select</option>
            <option value="name">Name</option>
            <option value="startDate">Date</option>
          </select>
          <select
            name=""
            id=""
            value={selectedEmployee}
            onChange={e => setSelectedEmployee(e.target.value)}
            className="outline-none w-32 rounded p-1 text-sm "
          >
            <option value="">Select </option>
            {employee != undefined &&
              employee.map(x => {
                return (
                  <option value={x.employee_id}>
                    {x.name} ({x.position && x.position.position}){' '}
                  </option>
                );
              })}
          </select>
          <SearchIcon />
        </article>
      </section>

      <hr />
      <div className="h-[70vh] scrollbar2 px-2 overflow-y-scroll ">
        {console.log('PENDING INSIDE', typeof data)}
        {typeof data === 'object' &&
          [...data]?.reverse()?.map((x, index) => {
            console.log(x);
            return (
              <div
                onClick={() => {
                  setTaskId(x.id);
                }}
                className={` `} >
                <TaskDashCard data={x} index={index} />
              </div>
            );
          })}
      </div>
      {(taskid || subTask) && (
        <ViewTaskData
          show={taskid}
          subTask={subTask}
          setSubTask={setSubTask}
          setshow={setTaskId}
        />
      )}
    </div>
  );
};

export default PendingList;
