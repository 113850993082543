import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Store } from '../../../Context/StateStore';
import { hostName } from '../../../App';
import DonutChart from '../../Analytics/DonutChart';

const EmployeeTasksAnalysis = () => {
  let [counts, setcounts] = useState();
  let [selectedEmployee, setSelectedEmployee] = useState();
  let { employee, employeeUnderUser } = useContext(Store);

  const [chartData, setChartData] = useState();

  let getStrategy = async () => {
    await axios
      .get(`${hostName}/api/CountsOfProjects/${selectedEmployee}`)
      .then(response => {
        console.log('EMPLOYEE ANALYTICS PERCENTAGE', response.data);
        setChartData(response.data.percentage);

        // setcounts([
        //   response.data.percentage.pending_tasks,
        //   response.data.percentage.completed_on_time,
        //   response.data.percentage.completed_after_time,
        //   response.data.percentage.over_due,
        // ]);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (selectedEmployee) getStrategy();
  }, [selectedEmployee]);

  useEffect(() => {
    employeeUnderUser();
  }, []);

  useEffect(() => {
    if (employee) setSelectedEmployee([...employee].reverse()[0].employee_id);
  }, [employee]);

  console.log('EMPLOYEE ANALYTICS COUNT', counts);

  const convertedData = [
    { value: chartData?.completed_tasks, name: 'Completed Tasks' },
    { value: chartData?.completed_on_time, name: 'Completed On Time' },
    { value: chartData?.completed_after_time, name: 'Completed After Time' },
    { value: chartData?.over_due, name: 'Overdue' },
    { value: chartData?.pending_tasks, name: 'Pending Tasks' },
  ];

  const option = {
    tooltip: {
      trigger: 'item',
    },
    color: ['#00D9C0', '#00ff00', '#fbff00', '#D80032', '#ff6f00'],

    legend: {
      show: false,
      bottom: '5%',
      left: '',
    },
    series: [
      {
        type: 'pie',
        radius: ['57%', '90%'],
        avoidLabelOverlap: false,
        padAngle: 2,
        itemStyle: {
          borderRadius: 4,
        },
        label: {
          show: false,
          position: 'center',
        },
        // emphasis: {
        //   label: {
        //     show: true,
        //     fontSize: 20,
        //     fontWeight: 'bold',
        //     z: 5,
        //   },
        // },
        labelLine: {
          show: true,
        },
        data: convertedData,
      },
    ],
  };

  return (
    <div className="h-max bg-white rounded-2xl w-full px-1 pt-2 pb-4 ">
      <section className="flex">
        <h5 className="text-lg"> Employee Analytics</h5>
        {console.log('EMPLOYEE NAME', selectedEmployee)}
        <select
          name=""
          id=""
          value={selectedEmployee}
          onChange={e => setSelectedEmployee(e.target.value)}
          className="outline-none w-32 rounded p-1 text-xs "
        >
          <option value="">Select </option>
          {employee !== undefined &&
            employee?.map(x => {
              return (
                <option value={x.employee_id}>
                  {x.name}({x?.position?.position})
                </option>
              );
            })}
        </select>
      </section>
      <DonutChart option={option} />
    </div>
  );
};

export default EmployeeTasksAnalysis;
