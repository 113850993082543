import React, { useEffect, useState } from 'react'
import Members from '../Members'
import ProjectList from '../ProjectList'
import AllTaskSpecification from './AllTaskSpecification'
import PendingList from '../PendingList'
import AdminDas3 from '../../AdminDas3'
import { axiosInstance } from '../../../api/AxiosClient'

const ParticularEmployeeData = ({ id, active, setActive }) => {
    let [data, setData] = useState()
    let getData = async () => {
        try {
            const response = await axiosInstance.get(
                `/api/ProjectsCountDisplay/${id}/?type=True&status=${active == 'task_history' ? 'all_projects' : active}`,
            );
            console.log(response.data, 'Personal Data');
            setData(response.data)
        } catch (error) {
            console.log(error);

        }

    }
    useEffect(() => {
        getData()
        document.getElementById('details').scrollIntoView({ behavior: 'smooth' })

    }, [active])

    return (
        <div>
            <div
                id="details"
                className="grid grid-cols-12 grid-rows-1 gap-x-2 mt-4 rounded-lg">
                <section className="col-span-12 rounded-2xl dascolbg">

                    {/* Approval history */}
                    {active === 'task_history' && (
                        <ProjectList data={data?.all_projects} />
                    )}
                    {/* All Task */}
                    {active === 'all_projects' && (
                        <AllTaskSpecification data={data?.[active]} />
                    )}

                    {active === 'pending' && (
                        <PendingList
                            data={data?.[active]}
                            title={'Ongoing Tasks'}
                            to={'/dashboard/pendingTask'}
                        />
                    )}
                    {active === 'over_due' && (
                        <PendingList
                            data={data?.[active]}
                            title={'Overdue Tasks'}
                            to={'/dashboard/overdue'}
                        />
                    )}
                    {active === 'in_progress' && (
                        <PendingList
                            data={data?.[active]}
                            title={'Pending Tasks'}
                            to={'/dashboard/ongoing'}
                        />
                    )}
                    {active === 'completed' && (
                        <PendingList
                            data={data?.[active]}
                            title={'Completed Tasks'}
                            to={'/dashboard/completedTask'}
                        />
                    )}
                    {active === 'completed_on_time' && (
                        <PendingList
                            data={data?.[active]}
                            title={'Completed On Time Tasks'}
                            to={'/dashboard/completedOnTime'}
                        />
                    )}
                    {active === 'completed_after_time' && (
                        <PendingList
                            data={data?.[active]}
                            title={'Completed After Tasks'}
                            to={'/dashboard/completedAfterTime'}
                        />
                    )}
                </section>
            </div>

            {active === 'approvals' && (
                <main className="col-span-12 rounded-2xl dascolbg">
                    <div className="rounded-lg h-full w-full p-2">
                        <AdminDas3 setActive={setActive} id={id} />
                    </div>
                </main>
            )}

        </div>
    )
}

export default ParticularEmployeeData