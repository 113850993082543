import React, { useState } from 'react'
import ViewTaskData from '../ViewTaskData';
import ProjectCreateModal from '../../AdminDashboard/ProjectCreateModal';
import DasDataSwitcher from '../../AdminDashboard/DasDataSwitcher';
import { useNavigate } from 'react-router-dom';

const AllTaskSpecification = ({ data }) => {
    console.log(data, 'projectList');
    let [taskid, setTaskId] = useState();
    let [subTask, setSubTask] = useState();
    let navigate = useNavigate();

    console.log('DATA', data);

    const [approved, setApproved] = useState('pendingAP' || '0');
    const [approveModal, setApproveModal] = useState(false);
    const [task, setTask] = useState();
    const handleChange = () => { };
    const submitTask = () => { };
    let tagSections = [
        {
            label: 'Task Request pending',
            key: 'pendingAP',
            condition: 'pending'
        },
        {
            label: 'Task Pending',
            key: 'pending',
        },
        {
            label: 'Over Due',
            key: 'over_due',
        },
        {
            label: 'Completed On Time',
            key: 'completed_on_time',
        },
        {
            label: 'Completed After Time',
            key: 'completed_after_time',
        },
        // {
        //     label: 'On Going',
        //     key: 'in_progress'
        // },

    ]
    return (
        <div className="rounded-lg p-3">
            <section className="flex justify-between items-center">
                <div>
                    <h5 className=" capitalize">Project activity</h5>
                </div>
                <button
                    onClick={() => navigate('/dashboard/tasklist')}
                    className="text-sm text-blue-600 fw-semibold "
                >
                    View All
                </button>
            </section>
            <DasDataSwitcher
                data={data}
                tags={tagSections}
                approved={approved}
                setApproved={setApproved}
            />
            {/* "completed_after_time" "over_due" "completed_on_time" "pending" */}
            <hr />
            <div className="h-[70vh] scrollbar2 px-2 overflow-y-scroll ">
                {data &&
                    [...data]
                        .filter(each => {
                            console.log('EACH', each.status, approved);
                            if (approved === 'pendingAP')
                                return each.approved_status === 'pending'
                            else
                                return each.status === approved && each.approved_status !== 'pending';
                        })
                        .reverse()
                        .map((x, index) => {
                            // console.log('EACH', x);
                            return (
                                <div
                                    onClick={() => {
                                        console.log('TASK', x.id);
                                        setTaskId(x.id);
                                    }}
                                    className="my-2 flex text-md items-center hover:bg-blue-50 cursor-pointer hover:scale-[1] scale-[0.97] duration-500 justify-between p-2 border-2 rounded "
                                >
                                    <p className="w-1/3 text-slate-900 fw-medium ">
                                        {' '}
                                        <span className='' > {index + 1} ) </span>  Task Name :
                                        <span className="block text-slate-500">
                                            {' '}
                                            {x.task_name}
                                        </span>{' '}
                                    </p>
                                    <p className="w-1/3 text-slate-900 fw-medium ">
                                        {' '}
                                        Assigned to :
                                        <span className="block text-slate-500">
                                            {x.name ? x.name : 'Not assigned'}
                                        </span>{' '}
                                    </p>
                                    <p className="w-1/3 text-nowrap text-slate-900 fw-medium ">
                                        {' '}
                                        Due Date:
                                        <span className="block text-slate-500">
                                            {x.due_date.slice(0, 10)}{' '}
                                        </span>
                                        <span className="block text-slate-500">
                                            {x.due_date.slice(11, 16)}{' '}
                                        </span>
                                    </p>
                                </div>
                            );
                        })}
            </div>

            {
                <ProjectCreateModal
                    setshow={setApproveModal}
                    show={approveModal}
                    settask={setTask}
                    task={task}
                    handleChange={handleChange}
                    submitTask={submitTask}
                />
            }

            {(taskid || subTask) && (
                <ViewTaskData
                    show={taskid}
                    subTask={subTask}
                    setSubTask={setSubTask}
                    setshow={setTaskId}
                />
            )}
        </div>
    );
};

export default AllTaskSpecification