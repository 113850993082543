import axios from 'axios';
import ENVTYPE from './config';

let hostName;
console.log(process.env.NODE_ENV, 'envlog');

if (ENVTYPE === 'development') {
  hostName = 'http://192.168.18.27:9000';
} else {
  hostName = 'https://dasbackendapi.meridahr.com';
}

export const axiosInstance = axios.create({
  baseURL:hostName,
});

axiosInstance.interceptors.request.use(config => {
  console.log(`URL:${config.url}`, `${hostName}${config.url}`);
  return config;
});
