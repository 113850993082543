function DasDataSwitcher({ approved, setApproved, data, tags }) {
  console.log(data, 'Vata');

  return (
    <div className="relative w-full flex">
      <div className="flex rounded-xl w-[90%] items-center justify-between mx-auto z-10">
        {
          tags?.map((obj) => (


            <button
              className={`relative p-2 w-fit transition-all duration-300 ${approved === obj.key
                ? 'border-b-4 border-orange-500 text-orange-500'
                : 'border-b-4 border-transparent text-gray-600'
                }`}
              onClick={() => setApproved(obj.key)}
            >
              {obj.label}
              <span className='bg-red-500 absolute text-slate-50 top-0 -translate-y-1 p-1 px-2 rounded text-[10px] ' >
                {data?.filter((obj2) => {
                  if (obj.key === 'pendingAP')
                    return obj2.approved_status === 'pending'
                  else
                    return obj2.status === obj.key && obj2.approved_status !== 'pending';
                })?.length}
              </span>
            </button>
          ))
        }
        {/* <button
          className={`relative p-2 transition-all duration-300 ${approved === 'pending'
            ? 'border-b-4 border-orange-500 text-orange-500'
            : 'border-b-4 border-transparent text-gray-600'
            }`}
          onClick={() => setApproved('pending')}
        >
          Not Approved
          <span className='bg-red-500 absolute text-slate-50 top-0 -translate-y-1 p-1 px-2 rounded text-[10px] ' >
            {data?.filter((obj) => obj.approved_status == 'pending')?.length}
          </span>
        </button>
        <button
          className={`relative p-2 transition-all duration-300 ${approved === 'rejected'
            ? 'border-b-4 border-orange-500 text-orange-500'
            : 'border-b-4 border-transparent text-gray-600'
            }`}
          onClick={() => setApproved('rejected')}
        >
          Rejected
          <span className='bg-red-500 absolute text-slate-50 top-0 -translate-y-1 p-1 px-2 rounded text-[10px] ' >
            {data?.filter((obj) => obj.approved_status == 'rejected')?.length}
          </span>
        </button> */}
      </div>
      <div className="w-full h-1 bg-slate-200 absolute bottom-0 z-0 mx-auto"></div>
    </div>
  );
}

export default DasDataSwitcher;
