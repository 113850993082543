import React from 'react'

const TaskDashCard = ({ data, index }) => {
    return (
        <div className={`  my-2 flex items-center hover:bg-blue-50   cursor-pointer scale-[0.97] hover:scale-[1] duration-500 
                                         justify-between p-2 border-2 rounded  `} >
            {/* ${data.status == 'over_due' && 'bg-red-100'} */}
            <p className="w-1/3 text-slate-900 fw-medium ">
                {' '}
                <span> {index + 1} ) </span> Task Name :
                <span className="block text-slate-500">
                    {' '}
                    {data.task_name}
                </span>{' '}
            </p>
            <p className="w-1/3 text-slate-900 fw-medium ">
                {' '}
                Assigned to :
                <span className="block text-slate-500">
                    {data.name ? data.name : 'Not assigned'}
                </span>{' '}
            </p>
            <p className="w-1/3 text-slate-900 fw-medium ">
                {' '}
                Due Date:
                <span className="block text-slate-500">
                    {data.due_date?.slice(0, 10)}{' '}
                </span>
            </p>
        </div>
    )
}

export default TaskDashCard