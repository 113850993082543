import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { hostName } from '../App';
import ProjectList from './AdminDas2/ProjectList';
import OverdueRight from './AdminDas2/OverdueRight';
import { Store } from '../Context/StateStore';
import { axiosInstance } from '../api/AxiosClient';
import { useQuery } from '@tanstack/react-query';

const AdminDas3 = ({ setActive, id }) => {
  let [activeset, setActiveSet] = useState('requests');
  let [overdueData, setOverDueData] = useState();
  let [completedData, setCompletedData] = useState();
  let { notification, setnotification, getNotification } = useContext(Store);

  const FetchApprovals = async () => {
    const response = await axiosInstance.get(
      `/api2/ProjectCreationByEmployee/${id ? id : JSON.parse(sessionStorage.getItem('dasid'))}/`,
    );

    return response.data;
  };

  const {
    data: approvalsData,
    isLoading,
    error,
    isError,
  } = useQuery({
    queryKey: ['AllApprovals'],
    queryFn: FetchApprovals,
  });

  approvalsData && console.log('APPROVALS', approvalsData);

  let getOverdue = () => {
    axios
      .get(
        `${hostName}/api/CreatedProjectsView/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/over_due/`,
      )
      .then(response => {
        setOverDueData(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  let getCompleted = () => {
    axios
      .get(
        `${hostName}/api/CreatedProjectsView/${JSON.parse(
          sessionStorage.getItem('dasid'),
        )}/completed/`,
      )
      .then(response => {
        setCompletedData(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOverdue();
    getCompleted();
  }, [id]);

  return (
    <div className="my-3">
      <div className=' flex justify-between flex-wrap w-full items-center ' >
        <p className="text-lg mb-0">Approvals</p>
        {/* <button className='' onClick={() => setActive('task_history')} >
          History
        </button> */}
      </div>
      {/* { <main className="py-3 border-b-2 relative flex justify-around">
        <button
          onClick={() => setActiveSet('overdue')}
          className={`relative ${
            activeset == 'overdue'
              ? 'text-orange-600  border--2 border-orange-600'
              : 'text-slate-500 '
          } transition duration-500  fw-medium `}
        >
          Overdue
          <span
            style={{ fontSize: '10px' }}
            className="badge absolute bg-red-500 -top-3  "
          >
            {overdueData && overdueData.length > 0 && overdueData.length}
          </span>
        </button>
        <button
          onClick={() => setActiveSet('requests')}
          className={`relative ${
            activeset == 'requests'
              ? 'text-orange-600  border--2 border-orange-600'
              : 'text-slate-500 '
          } transition duration-500  fw-medium `}
        >
          Approvals
          <span
            style={{ fontSize: '10px' }}
            className="badge absolute bg-red-500 -top-3  "
          >
            {notification &&
              notification.filter((x, index) => x.tipe).length > 0 &&
              notification.filter((x, index) => x.tipe).length}
          </span>
        </button>
        <button
          onClick={() => setActiveSet('completed')}
          className={`relative ${
            activeset == 'completed'
              ? 'text-orange-600  border--2 border-orange-600'
              : 'text-slate-500'
          } transition duration-500  fw-medium `}
        >
          Completed
          <span
            style={{ fontSize: '10px' }}
            className="badge absolute bg-red-500 -top-3  "
          >
            {completedData && completedData.length > 0 && completedData.length}
          </span>
        </button>
      </main>} */}
      {overdueData && activeset === 'overdue' && (
        <OverdueRight id={id} data={overdueData} />
      )}
      {completedData && activeset === 'completed' && (
        <OverdueRight id={id} data={completedData} />
      )}
      {notification && activeset === 'requests' && (
        <OverdueRight 
          // data={notification}
          data={approvalsData}
          type="request"
          id={id}
        />
      )}
      {console.log('NTFY', notification)}
    </div>
  );
};

export default AdminDas3;
