function CompletedCard({ fieldData, fieldImage, active, setActive }) {
  const detailsElement = document.getElementById('details');

  const items = [
    { label: 'Completed', value: fieldData?.completed, fieldName: 'completed' },
    {
      label: 'Completed On Time',
      value: fieldData?.completedOnTimeProjects,
      fieldName: 'completed_on_time',
    },
    {
      label: 'Completed After Time',
      value: fieldData?.completedAfterTimeProjects,
      fieldName: 'completed_after_time',
    },
  ];

  return (
    <div
      className={`bg-[#EBEDFD] px-3 gap-x-5 flex w-[474px] py-2 rounded-xl font-sans cursor-pointer`}
    >
      <div className="flex items-center justify-center bg-white rounded-full w-[56px] h-[56px] my-auto flex-shrink-0">
        <img
          className="object-fill p-2"
          src={fieldImage}
          alt="Icon"
        />
      </div>

      <div className="flex py-1 justify-between w-full">
        {items.map((item, index) => (
          <div
            key={index}
            className={`flex flex-col px-2 items-center gap-y-1 text-center ${
              active === item.label
                ? 'bg-[#6B6DD8] '
                : 'bg-slate-50 hover:bg-slate-100'
            }  rounded-lg p-1`}
            onClick={() => {
              detailsElement?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });

              setActive(item.fieldName);
            }}
          >
            <p
              className={`mb-0 capitalize text-xs ${
                active === item.label ? 'text-white' : 'text-black'
              }`}
            >
              {item.label}
            </p>
            <h2
              className={`text-md ${
                active === item.label ? 'text-white' : 'text-black'
              }`}
            >
              {item.value}
            </h2>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CompletedCard;
