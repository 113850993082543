import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { hostName } from '../App';
import { Store } from '../Context/StateStore';
import Loading from '../Component/Loading';
import DepDas from '../Component/DepDas';
import PlusIcon from '../SVG/PlusIcon';
import { Modal } from 'react-bootstrap';
import RupeeIcon from '../SVG/RupeeIcon';
import Calendar from '../Component/Dashboard/CalenderTAS/CalenderTAS';
import NavBarComponent from '../Component/NavBarComponent';
import { useLocation, useParams } from 'react-router-dom';
import useIsEmployee from '../utils/Locations/useIsEmployee';
import { axiosInstance } from '../api/AxiosClient';
import { toast } from 'react-toastify';

const Das = props => {
  const { emailId, empName, empDes, empdepartment, employID } = props;
  let [loading, setLoading] = useState()
  const {
    setactive,
    calenderDate,
    setCalenderDate,
    currentDate,
    das,
    user,
    getCurrentDate,
    getDas,
  } = useContext(Store);

  const { id } = useParams();

  const isEmployee = useIsEmployee();

  useEffect(() => {
    getDas();
  }, [das]);

  const [emailMessage, setEmailMessage] = useState({
    subject: 'Das update',
    message: '',
    apply_cc: false
  });
  let handleMailChange = (e) => {
    let { name, value } = e.target
    setEmailMessage((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  const [otherDepartment, setOtherDepartment] = useState('');
  const [departments, setDepartments] = useState([]);
  const [billingModel, setBillingModel] = useState('');

  const [openMail, setOpenMail] = useState('');

  const [billing_obj, setbilling_obj] = useState({
    billing_projected: 0,
    billing_acheived: 0,
    collection_projected: 0,
    collection_acheived: 0,
  });
  const email = JSON.parse(sessionStorage.getItem('email'));

  console.log('TODAYYY', calenderDate);
  const fetchDepartments = () => {
    axios
      .get(`${hostName}/api/DisplayDepartments/`)
      .then(response => {
        setDepartments(response.data.Departments);
        const department = response.data.Departments.find(
          obj => obj.id === empdepartment,
        );
        if (department) setOtherDepartment(department.department_name);
      })
      .catch(error => console.error('Error fetching departments:', error));
  };

  const getBillingDetails = date => {
    console.log('BILLING', date);

    axios
      .put(`${hostName}/api/BillingView/${sessionStorage.getItem('dasid')}/`, {
        date: date,
      })
      .then(response => {
        const billingData =
          response.data.length > 0 ? response.data[0] : billing_obj;
        setbilling_obj(billingData);
      })
      .catch(error => console.error(error));
  };

  const billing = () => {
    axios
      .post(
        `${hostName}/api/BillingView/${sessionStorage.getItem('dasid')}/`,
        billing_obj,
      )
      .then(() => {
        getDas(calenderDate, email);
        alert('Changes Made');
        setBillingModel('');
      })
      .catch(error => console.error(error));
  };

  useEffect(() => {
    if (empdepartment) fetchDepartments();
  }, [empdepartment]);

  useEffect(() => {
    setactive(emailId ? 'emp' : 'das');
    getDas(calenderDate, isEmployee ? emailId : email);
    getBillingDetails(calenderDate);
  }, [emailId]);

  const sendMail = () => {
    setLoading('mail')
// console.log(process.env.NODE_ENV);

    // return
    
    axiosInstance
      .post(`/api/PushDasReviewMail`, {
        message: emailMessage.message,
        subject: emailMessage.subject,
        apply_cc: emailMessage.apply_cc,
        emp_id: id,
        date: calenderDate,
      })
      .then(res => {
        toast.success('Mail Sent');
        setEmailMessage({
          subject: 'Das update',
          message: '',
          apply_cc: false
        });
        console.log(res);
        setLoading(false)
      }).catch((error) => {
        setLoading(false)
        console.log(error);
        toast.error('Error occured')
      });
  };

  console.log('IDDS', das);

  return (
    <div className="px-6  py-4 ">
      {user && das && currentDate ? (
        <>
          <Calendar
            setCalenderDate={setCalenderDate}
            getDas={getDas}
            getBillingDetails={getBillingDetails}
            calenderDate={calenderDate}
            email={email}
            emailId={emailId}
            employID={employID}
          />

          <div className="flex flex-col sm:flex-row gap-4 mt-6 justify-between bg-white p-4 rounded shadow-lg">
            <div className='  ' >
              <p>
                <strong>Name:</strong>{' '}
                <span className="uppercase">{empName || user.full_name}</span>
              </p>
              <p>
                <strong>Role:</strong>{' '}
                <span className="uppercase">{empDes || user.Position}</span>
              </p>
              <p>
                <strong>Reporting Time:</strong> 9:30 - 6:30
              </p>

            </div>
            <div>
              {JSON.parse(sessionStorage.getItem('status')) == 'admin' && <button
                className="bg-purple-500 w-max text-white px-4 py-1 rounded hover:bg-purple-600 transition"
                onClick={() => {
                  setOpenMail(true);
                }}
              >
                Write a Mail
              </button>}
            </div>
          </div>

          {user.status === 'manager' &&
            (user.Department === 'Sales' ||
              user.Department === 'Business Development') && (
              <main className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
                {[
                  'billing_projected',
                  'billing_acheived',
                  'collection_projected',
                  'collection_acheived',
                ].map(type => (
                  <article
                    key={type}
                    className="bg-white border-[1.4px] border-slate-600-2 p-4 rounded-lg shadow-lg flex flex-col gap-2 items-center hover:scale-105 transition transform"
                  >
                    <button
                      onClick={() => setBillingModel(type)}
                      disabled={getCurrentDate() !== calenderDate || emailId}
                      className="rounded-full p-2 text-violet-600 bg-pink-100 hover:bg-pink-200 border-[1.4px] border-slate-600-2 transition"
                    >
                      <PlusIcon size={20} />
                    </button>
                    <div className="text-center">
                      <p className="text-sm capitalize">
                        {type.replace(/_/g, ' ')}
                      </p>
                      <p className="text-xl font-bold flex items-center justify-center gap-1">
                        <RupeeIcon />
                        {billing_obj[type]}
                      </p>
                    </div>
                  </article>
                ))}
              </main>
            )}

          <div className="mt-10 mb-4">
            <DepDas
              emailId={emailId}
              updateObj={console.log}
              todayDate={currentDate}
              dasSheet={das}
            />
          </div>
        </>
      ) : (
        <Loading />
      )}

      {billingModel && (
        <Modal
          show={billingModel !== ''}
          onHide={() => setBillingModel('')}
          centered
        >
          <Modal.Header closeButton>
            {billingModel.replace(/_/g, ' ').toUpperCase()}
          </Modal.Header>
          <Modal.Body>
            <input
              onKeyDown={e => e.key === 'Enter' && billing()}
              onChange={e =>
                setbilling_obj(prev => ({
                  ...prev,
                  [billingModel]: e.target.value,
                }))
              }
              type="number"
              name={billingModel}
              placeholder="Amount"
              className="w-full border-[1.4px] border-slate-600-2 rounded p-2 mb-4 focus:outline-none focus:ring-2 focus:ring-violet-600"
            />
            <button
              onClick={billing}
              className="px-6 py-2 bg-violet-600 text-white rounded hover:bg-violet-700 transition"
            >
              Add
            </button>
          </Modal.Body>
        </Modal>
      )}
      {/* EMail kaah thing */}
      {openMail && (
        <Modal
          show={openMail !== ''}
          onHide={() => setOpenMail(false)}
          centered
          size="md"
        >
          <Modal.Header closeButton>Send Mail to {empName}</Modal.Header>
          <Modal.Body>
            <div className="">
              {/* Mail choices */}

            </div>
            <section className='border-[1.4px] border-slate-600 relative p-[8px] py-[10px] rounded ' >
              <p className='absolute top-0 py-0 my-0 text-xs -translate-y-1/2 px-1 bg-white left-3 w-fit z-10 ' >Email Id *</p>
              <div className=' border-[1.4px] border-slate-600 p-1 relative text-xs rounded bg-white w-fit '>{empName} </div>
            </section>
            <section className=' border-[1.4px] border-slate-600 relative p-[8px] py-[10px] rounded my-3 ' >
              <p className='absolute top-0 py-0 my-0 text-xs -translate-y-1/2 px-1 bg-white left-3 w-fit z-10 ' >Subject *</p>

              <input type="text" value={emailMessage.subject} name='subject'
                className=' w-full placeholder:text-slate-500 text-gray-700 outline-none'
                placeholde="Enter the Subject " onChange={handleMailChange} />


            </section>
            <textarea
              className="flex-1 w-full block text-wrap p-2 border-[1.4px] border-slate-600 rounded outline-none bg-transparent text-gray-700 placeholder-gray-400"
              type="text"
              name='message'
              value={emailMessage.message}
              placeholder="Type your message..."

              onChange={handleMailChange}
            />
            <div className='flex  items-center gap-1 text-sm my-3 ' >
              <input type="checkbox" value={emailMessage.apply_cc}
                checked={emailMessage.apply_cc}
                onClick={() => {
                  setEmailMessage((prev) => ({
                    ...prev,
                    apply_cc: !prev.apply_cc
                  }))
                }} id='addManager' className='cursor-pointer ' />
              <label htmlFor="addManager">Add Reporting Manager also</label>
            </div>
            <button
              className="bg-purple-500  w-max text-white px-4 py-1 flex float-end
               rounded hover:bg-purple-600 transition"
              onClick={() => {
                sendMail();
              }} disabled={loading == 'mail'} >
              {loading == 'mail' ? 'Loading...' : "Send"}
            </button>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default Das;
