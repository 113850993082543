import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from '../api/AxiosClient';
import StatsCard from './AdminDas2/Cards/StatsCard';
import FilledStatus from './AdminDas2/Cards/FilledStatus';
import PendingCard from './AdminDas2/Cards/PendingCard';
import CompletedCard from './AdminDas2/Cards/CompletedCard';

import {
  CompletedLogo,
  EmployeeLogo,
  PendingLogo,
  TasksLogo,
} from '../Assets/AllImages';

function DASCards({ active, setActive, setStatus, status, allEmpy }) {
  const FetchDashboardCount = async () => {
    const response = await axiosInstance.get(
      `/api/ProjectsCountDisplay/${JSON.parse(sessionStorage.getItem('dasid'))}/`,
    );
    console.log('COUNTT', response.data);
    return response.data.projects_count;
  };

  const {
    data: DASCount,
    isLoading,
    error,
    isError,
  } = useQuery({
    queryKey: ['AllCountDashboard'],
    queryFn: FetchDashboardCount,
  });

  return (
    <div className="flex flex-wrap gap-16  p-0 justify-center">
      <StatsCard
        fieldCount={DASCount?.allEmpProjects}
        fieldImage={TasksLogo}
        fieldName={'all_projects'}
        active={active}
        setActive={setActive}
        setStatus={setStatus}
      />

      <PendingCard
        fieldImage={PendingLogo}
        fieldData={DASCount}
        active={active}
        setActive={setActive}
      />
      <StatsCard
        fieldCount={allEmpy?.length}
        fieldImage={EmployeeLogo}
        fieldName={'employee'}
        active={active}
        setActive={setActive}
        setStatus={setStatus}
      />
      <StatsCard
        fieldCount={DASCount?.pending_approvals}
        fieldImage={EmployeeLogo}
        fieldName={'approvals'}
        active={active}
        setActive={setActive}
        setStatus={setStatus}
      />

      {console.log('ACTIVE', active)}

      <CompletedCard
        fieldData={DASCount}
        fieldImage={CompletedLogo}
        active={active}
        setActive={setActive}
      />

      <FilledStatus
        status={status}
        setStatus={setStatus}
        active={active}
        setActive={setActive}
      />
    </div>
  );
}

export default DASCards;
